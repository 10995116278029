import React from 'react';

import { Dialog } from './Dialog';

export const DeleteTagsDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  count,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void; count: number; }) => {
  if (isOpen) {
    const tagOrTags = count > 1 ? 'tags' : 'tag';

    return (
      <div>
        <Dialog
          id="delete-tags"
          title={`Delete ${count} ${tagOrTags}?`}
          subtitle={`Are you sure you want to delete the selected ${tagOrTags}? Deleting tags will remove them from all documents, highlights, and filtered views.`}
          actionTitle={`Delete ${count} ${tagOrTags}`}
          cancelTitle="Cancel"
          redActionButton
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }

  return null;
};
